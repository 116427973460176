<template>
	<div class="article-next">
		<img src="~@/assets/bgArticleNext.jpg" alt="Next Article" class="article-next__topper" />
		<div class="article-next__content-wrapper">
			<div class="article-next__label article-next__label--label"><slot name="label">Label Goes Here</slot></div>
			<router-link :to="link" class="article-next__content" :id="targetId">
				<div class="article-next__container" :style="bgImage">
					<div class="article-next__text">
						<h2>NEXT</h2>
					</div>
				</div>
			</router-link>
			<div class="article-next__label article-next__label--title"><slot name="title">Title Goes Here</slot></div>
		</div>
	</div>
</template>

<script>

// Export
export default {
	name: 'ArticleNext',
	props: {
		items: {
			type: [Array, Object],
			default: () => ([]),
		},
		image: {
			type: String,
		},
		link: {
			type: Object,
			default: () => ({}),
		},
		targetId: String,
	},
	computed: {
		bgImage() {
			const styles = {}
			if (this.image) {
				//styles.backgroundImage = `url(${require(this.image)})`
				styles.backgroundImage = `url(${require('@/assets/'+ this.image)})`
			}
			return styles
		},
	},
}
</script>

<style lang="scss" scoped>

$min-height-desktop: 380px;
$min-height-mobile: 320px;

$max-height-desktop: 420px;

.article-next {
	@include gutter('padding-bottom', 8);
	background-color: color_('primary','dark');

	&__topper {
		width: 100%;
		max-width: 100%;
		height: auto;
	}

	&__content-wrapper {
		@include gutter('padding', 1.5);
		//@include gutter('padding-top', 1.5);
		position: relative;
		margin-left: auto;
		margin-right: auto;

		@include media-query-min-width('tablet') {
			max-width: 1000px;
		}

		@include media-query-min-width('desktop') {
			@include gutter('padding', 0);
			@include gutter('padding-top', 1.5);
		}

	}

	&__label {
		@include fontSize(14px);
		@include gutter('margin-bottom', 1.25);
		@include gutter('margin-top', 1.25);
		color: rgba(color_('primary', 'text'), .75);
		text-transform: uppercase;
		letter-spacing: 1.5px;

		&--title {
			text-align: right;
		}

		&--label {

		}
	}

	&__content {
		@include ratio('film');
		display: block;
		min-height: $min-height-mobile;
		position: relative;

		@include media-query-min-width('desktop') {
			min-height: $min-height-desktop;
			// margin-left: auto;
			// margin-right: auto;
		}
	}

	&__container {
		@include gutter('padding', 2.5);
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center;
		background-color: black;
		display: flex;
		flex-direction: column;
		justify-content: center;
		width: 100%;

		box-shadow: $initial-box-shadow;
		transform: scale(1);
		transition: box-shadow 150ms $ease-standard, transform 150ms $ease-standard;

		@include media-query-min-width('desktop') {
			padding-left: $column;
			padding-right: $column;
		}

		&:hover {
			cursor: pointer;
			box-shadow: $hover-box-shadow;
			transform: scale(1.1);
			z-index: 1;
		}
	}

	&__text {
		text-align: center;

		figure {
			@include fontSize(20px);
			@include gutter('margin-bottom', .75);
			font-family: $font-family-secondary;
			letter-spacing: 1px;
			color: color_('primary', 'text');
			text-transform: uppercase;
			margin: 0;
			text-shadow: $textshadow-1;

			@include media-query-min-width('tablet') {
				@include fontSize(24px);
			}

		}

		h2 {
			@include fontSize(56px);
			@include gutter('margin-bottom', 0);
			color: color_('primary', 'text');
			font-family: $font-family-secondary;
			font-weight: bold;
			margin-top: 0;
			text-transform: uppercase;
			line-height: .9;
			text-shadow: $textshadow-1;

			@include media-query-min-width('tablet') {
				@include fontSize(70px);
			}

			@include media-query-min-width('desktop') {
				@include fontSize(96px);
			}
		}
	}

}

</style>
